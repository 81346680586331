vue
Copy code
<template>
  <v-alert
    v-if="store.isAuthenticated && $auth.currentUser"
    text
    prominent
    :type="props.type"
  >
    <div class="d-flex align-center">
      <div class="flex-grow-1">
        {{ $t('Alert403.verifyEmail', { email: $auth.currentUser.email }) }}
      </div>
      <div class="flex-grow-9">
        <v-btn
          variant="outlined"
          @click="$auth.currentUser.sendEmailVerification()"
        >
          {{ $t('Alert403.resendEmailVerification') }}
        </v-btn>
      </div>
    </div>
  </v-alert>
</template>

<script setup>
import { useStore } from '@/composables/store'
const store = useStore()
const { $auth } = useNuxtApp()

const props = defineProps({
  type: {
    type: String,
    default: 'info',
  },
})
</script>